import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    {/* <span className="sub-title">
                        <img src={starIcon} alt="icon" /> 
                        Our Solutions
                    </span> */}
                    <h2 className="homepage-tittle">Why Tafoma?</h2>
                    <p className="purple-text">Platform made specially for SMEs.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <StaticImage src={'../../assets/images/homepage/cut-cost.png'} 
                                        alt="Money stays in the wallet." 
                                        className="single-solutions-box-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}/>
                            </div>
                            <h3>
                                Cut Cost
                            </h3>
                            <p>Why pay for 3 or 4 services, when you can use a service that provides all the features in one place for one price.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <StaticImage src={'../../assets/images/homepage/no-integration.png'} 
                                        alt="Person chosing from many options." 
                                        className="single-solutions-box-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"/>
                            </div>
                            <h3>
                            No Integrations 
                            </h3>
                            <p>There is no need for you to spend time and effort integrating the tools that you need, Tafoma provides them all.</p>

                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <StaticImage src={'../../assets/images/homepage/improves-productivity.png'} 
                                        alt="Person next to speedometer." 
                                        className="single-solutions-box-img"
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"/>
                            </div>
                            <h3>
                            Improves Productivity
                            </h3>
                            <p>Tafoma is the easiest way for you to improve your teams’ productivity, no matter if your work remotely or not.</p>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions