import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
// import ReactWOW from 'react-wow's
import { Link } from 'gatsby'
import ModalVideo from 'react-modal-video'



const Banner = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    const [email, setEmail] = React.useState(" ");
    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            <ModalVideo
                channel='youtube'
                isOpen={!isOpen}
                videoId='kI_FOQHfn6M'
                onClose={() => setIsOpen(!isOpen)}
            />
            <div className="seo-agency-banner">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="banner-wrapper-content">
                                {/* <span className="sub-title">SEO Agency</span> */}
                                {/* <ReactWOW delay='.1s' animation='fadeInLeft'> */}
                                    <h1><span className="purple-text">One Tool </span> for All Your Collaboration Needs</h1>
                                {/* </ReactWOW> */}

                                {/* <ReactWOW delay='.1s' animation='fadeInLeft'> */}
                                    <p>Tafoma is the only software which provides everything for team&nbsp;collaboration in <b>one place</b>, with <b>no integration</b>.</p>
                                {/* </ReactWOW> */}

                                {/* <ReactWOW delay='.1s' animation='fadeInRight'> */}
                                    <div className="input1 input-group mb-3">
                                        <input type="text" onChange={event => setEmail(event.target.value)} className="form-control1 form-control" placeholder="Enter your email" aria-label="Enter your email" aria-describedby="button-addon2" />
                                        <a href={`https://app.tafoma.com/reg/?eml=${email}`}>
                                            <button className="default-btn1 default-btn" type="button" id="button-addon2 default-btn1">Free Sign Up<span></span></button>
                                       </a>
                                        {/* http://app.tafoma.com/reg/?eml=john.s@tafoma.com */}
                                    </div>
                                    <p>&nbsp;<sup>*No Credit Card Required</sup></p>
                                {/* </ReactWOW> */}

                                {/* <ReactWOW delay='.1s' animation='fadeInLeft'> */}
                                    {/* <p> */}
                                    {/* <Link
                                        to="#"
                                        onClick={e => { e.preventDefault(); openModal() }}
                                        className="video-btn popup-youtube">
                                        {/* <i className="flaticon-google-play play-button"></i> Watch Video
                                       <div className="row">
                                           <i class='bx bx-play col'></i>
                                            <p className='col'>Watch Video</p>
                                       </div> 
                                    </Link> */}
                                    {/* </p> */}
                                {/* </ReactWOW> */}
                                <Link
                                        to="#"
                                        onClick={e => { e.preventDefault(); openModal() }}
                                        className="round-button1 row zzs popup-youtube">
                                            <div className="round-button col">
                                            <i class='bx bx-play'></i>
                                            </div>
                                            <div className="col second-bx">
                                            Watch Video
                                            </div>

                                        </Link>
                                
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            {/* <ReactWOW delay='.1s' animation='fadeInUp'> */}
                                <div className="banner-wrapper-image">
                                    {/* <img src={bannerImg2} alt="banner" /> */}
                                    <StaticImage src={'../../assets/images/banner-img2.png'} 
                                        alt="Tafoma running on a computer, a tablet and a phone." 
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                        placeholder="none"
                                        fadeIn="false"
                                        loading="eager"/>
                                </div>
                            {/* </ReactWOW> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Banner