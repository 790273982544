import React from 'react'
import loadable from '@loadable/component'
import Layout from "../components/App/Layout"
import Helmet from 'react-helmet'
import Navbar from "../components/App/Navbar"
import Banner from '../components/SEOAgency/Banner'
import Solutions from '../components/SEOAgency/OurSolutions'

const CarouselTools = loadable(() => import('../components/SEOAgency/CarouselTools'));
const Available = loadable(() => import('../components/SEOAgency/Available'));
const Testimonials = loadable(() => import('../components/SEOAgency/Testimonials'));
const Partner = loadable(() => import('../components/SEOAgency/Partner'));
const Industries = loadable(() => import('../components/SEOAgency/Industries'));
const StartProject = loadable(() => import('../components/SEOAgency/Connect'));
const Footer = loadable(() => import('../components/App/Footer'));


const SeoAgency = () => {
    return (
        <Layout>
            <Helmet htmlAttributes={{lang: 'en'}}>
            <script src="https://app.tafoma.com/wpc/tafoma.js?ref=1cc885bda4ce217db46489163bed2b15" async></script>
            </Helmet>
            <Navbar />
            <Banner />
            <Solutions />
                <CarouselTools />
                <Industries />
                <Available />
                <StartProject />
                <Partner />
                <Testimonials />
                <Footer />
        </Layout>
    )
}

export default SeoAgency
